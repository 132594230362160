.services {
    padding: 0 12rem;
    background-color: #fff;
}

.features {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.features h2 {
    text-align: right;
    color: #38B2AC;
    padding-right: 13rem;
    margin-bottom: 2rem;
}

.features-cards {
    display: flex;
    gap: 2rem;
    padding: 0 12rem;
}

.features-cards .card {
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 260px;
    height: 268px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: transform 0.3s;
}

.features-cards .card:hover {
    transform: translateY(-5px);
}

.features-cards .card .icon {
    width: 40px;
    height: 40px;
    fill: #38B2AC;
}

.features-cards .card h3 {
    color: #38B2AC;
    margin: 0.5rem 0;
}

.features-cards .card p {
    color: #555;
    margin: 0;
}

.instructions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.instructions h2,
.instructions .description {
    text-align: left;
    color: #38B2AC;
    padding-left: 12rem;
}

.instructions .description {
    margin-bottom: 2rem;
    color: #555;
    width: 900px;
}

.steps {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 4rem;
}

.steps-left,
.steps-right {
    display: flex;
    flex-direction: column;
}

.steps-left {
    gap: 10rem;
}

.steps-right {
    margin-top: 12rem;
    gap: 10rem;
}

.steps-left .card,
.steps-right .card {
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 542px;
    height: 219px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: transform 0.3s;
}

.steps-left .card:hover,
.steps-right .card:hover {
    transform: translateY(-5px);
}

.steps-left .card .icon,
.steps-right .card .icon {
    width: 40px;
    height: 40px;
    fill: #38B2AC;
}

.steps-left .card h3,
.steps-right .card h3 {
    color: #38B2AC;
    margin: 0.5rem 0;
}

.steps-left .card p,
.steps-right .card p {
    color: #555;
    margin: 0;
}

.steps-divider {
    position: absolute;
    width: 2px;
    background-color: transparent;
    border-left: 2px dotted #38B2AC;
    height: 1200px;
}

.steps-divider .circle {
    position: absolute;
    top: -12px;
    left: -7px;
    width: 12px;
    height: 12px;
    background-color: #38B2AC;
    border-radius: 50%;
}

.steps-mobile .card {
    background: #F8F8F8;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: transform 0.3s;
    width: 100%;
}

@media (max-width: 768px) {
    .services {
        padding: 0 2rem;
    }

    .features h2 {
        text-align: left;
        padding-right: 0;
    }

    .features-cards {
        flex-direction: column;
        padding: 0;
    }

    .features-cards .card {
        width: 100%;
    }

    .instructions h2,
    .instructions .description {
        padding-left: 0;
    }

    .instructions .description {
        width: 100%;
    }

    .steps {
        flex-direction: column;
        gap: 2rem;
    }

    .steps-left,
    .steps-right {
        gap: 2rem;
    }

    .steps-right {
        margin-top: 0;
    }

    .steps-left .card,
    .steps-right .card {
        width: 100%;
        height: auto;
    }

    .steps-divider {
        display: none;
    }

    .steps-mobile {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .services {
        padding: 0;
    }

    .features-cards {
        display: flex;
        gap: 2rem;
        padding: 0 1rem;
    }

    .instructions h2,
    .instructions .description {
        padding: 0 1rem;
    }

    .steps {
        gap: 2rem;
        flex-direction: row;
        align-items: center;
        padding: 0 1rem;
    }

    .steps-left,
    .steps-right {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8rem;
        width: 100%;
    }

    .steps-left {
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
    }

    .steps-right {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
    }

    .steps-left .card,
    .steps-right .card {
        width: 100%;
        max-width: 300px;
        height: auto;
    }



    .steps-divider {
        position: absolute;
        width: 2px;
        background-color: transparent;
        border-left: 2px dotted #38B2AC;
        height: 1000px;
    }

    .steps-divider .circle {
        position: absolute;
        top: 0;
        left: -7px;
        width: 12px;
        height: 12px;
        background-color: #38B2AC;
        border-radius: 50%;
    }



}