.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #20A2A0;
    padding: 4rem 22rem;
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 70%, calc(0% + 50px) 100%, 0 100%);
    border-bottom-left-radius: 50px;
}

.banner-info {
    flex: 1;
    color: white;
    text-align: left;
}

.banner-info h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.banner-info ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
}

.banner-info ul li {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.banner-buttons {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
}

.banner-buttons .primary-button {
    background-color: white;
    color: #38B2AC;
    width: 227px;
    height: 45px;
    padding: 10px 24px;
    border-radius: 24px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
}

.banner-buttons .primary-button:hover {
    background-color: #e0e0e0;
}

.banner-buttons .secondary-button {
    background-color: transparent;
    color: white;
    width: 227px;
    height: 45px;
    padding: 10px 24px;
    border-radius: 24px;
    border: 2px solid white;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.banner-buttons .secondary-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.banner-image {
    flex: 1;
    text-align: right;
    position: relative;
}

.banner-image img {
    max-width: 100%;
    height: auto;
    display: block;
    position: relative;
    bottom: -10px;
}

.button-icon {
    margin-right: 10px;
    width: 28px;
    height: 28px;
}

@media (max-width: 768px) {
    .banner {
        flex-direction: column;
        padding-top: 1rem;
        padding-right: 1rem;
        padding-bottom: 9rem;
        padding-left: 1rem;
        clip-path: polygon(0 0, 100% 0, 100% 80%, 13% 100%, 0 100%);
        border-bottom-left-radius: 50px;
    }

    .banner-info {
        text-align: center;
    }

    .banner-info h1 {
        font-size: 2.6rem;
        margin-bottom: 1rem;
        text-align: left;
    }

    .banner-info ul {
        margin: 1rem 0;
        padding-left: 1rem;
        text-align: left;
    }

    .banner-info ul li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    .banner-buttons {
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;
    }

    .banner-buttons .primary-button,
    .banner-buttons .secondary-button {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .banner-buttons .primary-button {
        margin-bottom: 0.5rem;
    }

    .banner-buttons .secondary-button {
        margin-bottom: 1rem;
    }

    .banner-image {
        display: none;
    }

    .button-icon {
        margin-right: 0.5rem;
        width: 24px;
        height: 24px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .banner {
        padding: 2rem 5rem;
        clip-path: polygon(0 0, 100% 0, 100% 80%, calc(0% + 50px) 100%, 0 100%);
        border-bottom-left-radius: 40px;
    }

    .banner-info h1 {
        font-size: 2.2rem;
    }

    .banner-info ul li {
        font-size: 1.1rem;
    }

    .banner-buttons .primary-button,
    .banner-buttons .secondary-button {
        width: 200px;
        height: 40px;
        padding: 8px 20px;
        font-size: 0.9rem;
    }

    .button-icon {
        width: 26px;
        height: 26px;
    }

    .banner-image img {
        max-width: 100%;
        height: auto;
        display: block;
        position: relative;
        bottom: -40px;
    }
}